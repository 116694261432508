import React from 'react';
import Link from '/src/components/LocalizedLink';
import { Helmet } from 'react-helmet';

import Layout from '../../components/Layout';

import IconWhatsApp from '../../assets/images/svg/whatsapp.inline.svg';
import IconArrowRight from '../../assets/images/svg/arrow-right.inline.svg';

import fileTEFLIberiaBrochure from '../../assets/files/TEFL Iberia brochure web.pdf';

import '../../assets/styles/pages/terms-and-privacy-page.scss';

const ApplyThankYou = () => {
  return (
    <>
      <Helmet>
        {/* <!-- Meta Pixel Code --> */}
        <meta name="facebook-domain-verification" content="7dgbxitwc2e5jw9goe17a2mhn2aroq" />
        <script>
          {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1389098578493723');
            fbq('track', 'PageView');`}
        </script>
        <noscript>
          {`<img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=1389098578493723&ev=PageView&noscript=1"
            />`}
        </noscript>
        {/* <!-- End Meta Pixel Code --> (edited)  */}
      </Helmet>

      <Layout title="Thank you for your interest!" description="Many thanks for your downloading!">
        <div className="terms-and-privacy-page">
          <div className="container">
            <div className="wrapper">
              <h1 className="c-title-42" style={{ textAlign: 'left', marginBottom: 40 }}>
                Thank you for downloading the Free Ebook!
              </h1>
              <div className="subsection">
                <p className="subsection__par">
                  Your download will start in few seconds. If this doesn't happen,{' '}
                  <a
                    className="red-link"
                    href={fileTEFLIberiaBrochure}
                    download="brochure-2022.pdf"
                  >
                    click here
                  </a>{' '}
                  to get your copy.
                </p>
                <p className="subsection__par">The TEFL Iberia Team</p>
                <Link to="/" className="c-btn c-btn--red mt-50">
                  Return to the home page
                  <IconArrowRight />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ApplyThankYou;
